import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FaUserTie, FaBuilding, FaBriefcase, FaIdCard, FaGift } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const packages = [
  {
    title: "Individual Plan (Card Only)",
    description: "A simple and effective solution for individuals needing a digital business card with QR and NFC support.",
    features: [
      "Personalized digital business card",
      "QR Code and NFC for instant sharing",
      "Custom card design for branding",
      "Card replacement and upgrade options",
    ],
    icon: <FaIdCard className="text-5xl text-purple mx-auto mb-4" />,
  },
  {
    title: "Individual Plan (Full Package)",
    description: "A premium package for individuals who want additional accessories along with their digital business card.",
    features: [
      "Multiple personalized digital business cards",
      "QR Code and NFC for seamless sharing",
      "Card replacement and upgrade services",
      "Custom keychains with NFC tech and branding option",
    ],
    icon: <FaGift className="text-5xl text-purple mx-auto mb-4" />,
  },
  {
    title: "Corporate Plan (Cards & Trophy)",
    description: "Ideal for businesses that want digital business cards with a professional QR Code display.",
    features: [
      "Digital business cards for employees",
      "Supports small & large teams",
      "QR & NFC-enabled cards",
      "Company-branded designs",
    ],
    icon: <FaBuilding className="text-5xl text-purple mx-auto mb-4" />,
  },
  {
    title: "Corporate Plan (Full Package)",
    description: "A complete digital business solution for companies integrating NFC & QR Code technology.",
    features: [
      "Bulk employee digital business cards",
      "Company-branded QR & NFC cards",
      "Custom branding, NFC Keychains & landing pages",
      "NFC Wristwatch & QR Code Crystal for employees",
    ],
    icon: <FaBriefcase className="text-5xl text-purple mx-auto mb-4" />,
  },
];

const ContactPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full shadow-lg relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-600 hover:text-gray-800">
          <MdClose className="text-2xl" />
        </button>
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Contact Sales</h2>

        <Formik
          initialValues={{
            fullName: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          }}
          validationSchema={Yup.object({
            fullName: Yup.string().required("Full name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            phone: Yup.string().matches(/^\d+$/, "Phone number is not valid").required("Phone number is required"),
            company: Yup.string().required("Company name is required"),
            message: Yup.string().required("Message is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              console.log("Form submitted:", values);
              setSubmitting(false);
              onClose();
            }, 1000);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4">
              <div>
                <label className="block text-gray-700">Full Name</label>
                <Field type="text" name="fullName" className="w-full px-4 py-2 border rounded-lg focus:ring-purple-600 focus:outline-none" />
                <ErrorMessage name="fullName" component="div" className="text-red-500 text-sm" />
              </div>

              <div>
                <label className="block text-gray-700">Email Address</label>
                <Field type="email" name="email" className="w-full px-4 py-2 border rounded-lg focus:ring-purple-600 focus:outline-none" />
                <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
              </div>

              <div>
                <label className="block text-gray-700">Phone Number</label>
                <Field type="text" name="phone" className="w-full px-4 py-2 border rounded-lg focus:ring-purple-600 focus:outline-none" />
                <ErrorMessage name="phone" component="div" className="text-red-500 text-sm" />
              </div>

              <div>
                <label className="block text-gray-700">Company Name</label>
                <Field type="text" name="company" className="w-full px-4 py-2 border rounded-lg focus:ring-purple-600 focus:outline-none" />
                <ErrorMessage name="company" component="div" className="text-red-500 text-sm" />
              </div>

              <div>
                <label className="block text-gray-700">Message</label>
                <Field as="textarea" name="message" className="w-full px-4 py-2 border rounded-lg focus:ring-purple-600 focus:outline-none" rows="4" />
                <ErrorMessage name="message" component="div" className="text-red-500 text-sm" />
              </div>

              <button type="submit" className="w-full bg-purple text-white font-semibold py-3 rounded-lg hover:opacity-90 transition-opacity" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const PricingSection = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <section className="bg-white w-full py-16" id="pricing">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-semibold text-gray-800 mb-8 text-center">
          Our Packages
        </h2>

        <p className="text-lg text-gray-600 text-center max-w-3xl mx-auto mb-8">
          Explore our range of digital business card solutions tailored for individuals and businesses. Get in touch with our sales team for more details.
        </p>

        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 2 },
          }}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 6000, disableOnInteraction: false }}
          onSwiper={(swiper) => {
            swiper.el.addEventListener("mouseenter", () => swiper.autoplay.stop());
            swiper.el.addEventListener("mouseleave", () => swiper.autoplay.start());
          }}
          modules={[Navigation, Pagination, Autoplay]}
          className="w-full max-w-6xl mx-auto"
        >
          {packages.map((pkg, index) => (
            <SwiperSlide key={index} className="bg-gray-100 rounded-lg py-6 sm:px-12 px-4 shadow-md text-center">
              {pkg.icon}
              <h3 className="text-xl font-semibold mb-4 text-purple-header">{pkg.title}</h3>
              <p className="text-gray-600 mb-4">{pkg.description}</p>
              <ul className="text-gray-600 mb-4 text-left space-y-2">
                {pkg.features.map((feature, i) => (
                  <li key={i} className="flex items-center">
                    ✅ {feature}
                  </li>
                ))}
              </ul>
              <button onClick={() => setPopupOpen(true)} className="bg-purple text-white px-6 py-3 rounded-lg font-semibold hover:opacity-90">
                Contact Sales
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <ContactPopup isOpen={popupOpen} onClose={() => setPopupOpen(false)} />
    </section>
  );
};

export default PricingSection;
